export const colorPalette = [
  "#FF5733",
  "#FF8C00",
  "#FFC300",
  "#FFD700",
  "#FFA07A",
  "#FF7F50",
  "#FF6347",
  "#FF0000",
  "#DC143C",
  "#B22222",
  "#8B0000",
  "#FF69B4",
  "#FF1493",
  "#C71585",
  "#9400D3",
  "#6A5ACD",
  "#4169E1",
  "#0000FF",
  "#00008B",
  "#000080",
  "#00CED1",
  "#008080",
  "#006400",
  "#008000",
  "#228B22",
  "#32CD32",
  "#00FF7F",
  "#2E8B57",
  "#006400",
];

export const getRandomColor = () => {
  return colorPalette[Math.floor(Math.random() * colorPalette.length)];
};
